
import * as t from '@root/src/store/chisai/GCB2/actionTypes'
import Vue, { Component, PropType } from 'vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import { Connector, Project } from '../../types/main'
import TableActiveSwitchVue from '../../components/chisai/GCB2/activation/TableActiveSwitch.vue'
import {
    Activation,
    ChangeActivationMode,
    EditActivationStep,
    EditedActivation,
    IGCB2Connector,
    ManualCommunication,
} from '@/types/GCB2'
import { CHANGE_ACTIVATION_TYPES } from '../../vars/GCB2'
import NameAndDescriptionSettings from '@/components/chisai/GCB2/changeActivation/NameAndDescriptionSettings.vue'
import AudienceSettings from '@root/src/components/chisai/GCB2/changeActivation/AudienceSettings/AudienceSettings.vue'
import MessageVariablesSettings from '@root/src/components/chisai/GCB2/changeActivation/MessageVariablesSettings.vue'
import _, { isNil, uniqueId } from 'lodash'
import { replaceElementAtIndex } from '../../utils'
import EditActivationCopyButton from '../../components/chisai/GCB2/changeActivation/EditActivationCopyButton.vue'
import {
    createActivation,
    deleteActivation,
    getActivations,
    getMessage,
    setChangedActivationNotification,
    updateActivation,
} from '../../api/chisai/GCB2'
import MessageSettings from '../../components/chisai/GCB2/changeActivation/MessageSettings.vue'
import WorkModeSetting from '../../components/chisai/GCB2/changeActivation/WorkModeSetting.vue'
import SpinnerLoader from '../../components/SpinnerLoader.vue'
import { CONNECTOR_TYPES } from '../../vars/general'
import { checkRole } from '../../helpers/roles'
import AutomailSettings from '../../components/chisai/GCB2/changeActivation/AutomailSettings.vue'
import CommunicationTagSettings from '../../components/chisai/GCB2/changeActivation/CommunicationTagSettings.vue'
import BotSettings from '../../components/chisai/GCB2/changeActivation/BotSettings.vue'
import { editActivationService } from '../../services/editActivation'
import BatchModeSettings from '../../components/chisai/GCB2/changeActivation/BatchModeSettings.vue'
import messages from '@dataheroes/messages'
import MediaStep from '@/components/chisai/GCB2/bot/EditSteps/MediaStep.vue'
import api from '@root/src/api/api'
import { useBillingStore } from '@/store/stores/billing'
import { useModalStore } from '@/store/stores/modal'

const getMessagingConnector = (project: Project) => messages.getMessagingConnector(project.connectors)

export default Vue.extend({
    components: {
        Breadcrumbs,
        TableActiveSwitchVue,
        EditActivationCopyButton,
        MessageSettings,
        SpinnerLoader,
        MediaStep,
    },

    data: () => ({
        CHANGE_ACTIVATION_TYPES,
        loading: true as boolean,
        activationIsRunning: false as boolean,
        stepsIndexes: [] as number[] | number,
        activeStepsIndexes: [] as number[] | number,
        activationsList: [] as Activation[] | null,
        copyLoading: false as boolean,
        createOrEditLoading: false as boolean,
        mode: null as ChangeActivationMode | null,
        activationIdFromQuery: null as number | null,
        activation: {} as EditedActivation,
        automailValidate: {
            isAutomail: false,
            isValidScripts: false,
            isSaveButtonClicked: false,
        },
        steps: ([
            {
                id: 1,
                title: 'Название и описание',
                contentComponent: NameAndDescriptionSettings,
                ref: 'setting-1',
                saved: false,
                highlighted: false,
                saveBtnDisabled: true,
            },
            {
                id: 2,
                title: 'Аудитория',
                contentComponent: AudienceSettings,
                ref: 'setting-2',
                saved: false,
                highlighted: false,
                saveBtnDisabled: true,
            },
            {
                id: 3,
                title: 'Скрипт',
                contentComponent: MessageSettings,
                ref: 'setting-3',
                saved: false,
                highlighted: false,
                saveBtnDisabled: true,
            },
            {
                id: 4,
                title: 'Переменные для подстановки',
                contentComponent: MessageVariablesSettings,
                ref: 'setting-4',
                saved: false,
                highlighted: false,
                saveBtnDisabled: true,
            },
            {
                id: 5,
                title: 'Выбор бота',
                contentComponent: BotSettings,
                ref: 'setting-5',
                saved: false,
                highlighted: false,
                saveBtnDisabled: false,
            },
            {
                id: 6,
                title: 'Автоматическая рассылка',
                contentComponent: AutomailSettings,
                ref: 'setting-6',
                saved: false,
                highlighted: false,
                saveBtnDisabled: true,
            },
            {
                id: 7,
                title: 'Пакетная обработка',
                contentComponent: BatchModeSettings,
                ref: 'setting-7',
                saved: false,
                highlighted: false,
                saveBtnDisabled: false,
                showCondition: (project: Project) => getMessagingConnector(project),
            },
            {
                id: 9,
                title: 'Режим работы',
                contentComponent: WorkModeSetting,
                ref: 'setting-9',
                saved: false,
                saveBtnDisabled: false,
            },
            // {
            //     id: 9,
            //     title: 'Медиафайлы',
            //     contentComponent: MediaStep,
            //     ref: 'setting-9',
            //     saved: false,
            //     saveBtnDisabled: false,
            // },
        ] as any) as EditActivationStep[],
    }),
    setup: () => {
        const modalStore = useModalStore()
        return {
            modalStore,
        }
    },
    watch: {
        'automailValidate.isSaveButoonClicked'(val): void {
            if (val) {
                this.highlightStep(3)
            }
        },
        stepsIndexes(): void {
            if (this.mode === CHANGE_ACTIVATION_TYPES.create) {
                this.activeStepsIndexes = Array.isArray(this.stepsIndexes)
                    ? [...this.stepsIndexes]
                    : [this.stepsIndexes].filter(el => !isNil(el))
            }
        },
        $route: {
            immediate: true,
            deep: true,
            handler() {
                this.mode = this.$route.query.mode as ChangeActivationMode
                this.activationIdFromQuery = Number(this.$route.query.activationId)
            },
        },
        loading(): void {
            const highlightedStepId = this.$route.query.highlightedStepId
            if (!this.loading && highlightedStepId) {
                setTimeout(() => this.highlightStep(Number(highlightedStepId)), 200)
            }
        },
    },
    computed: {
        title(): string {
            return this.mode === CHANGE_ACTIVATION_TYPES.create ? 'Новая активация' : 'Редактирование'
        },
        expandDisabled(): boolean {
            return this.mode === CHANGE_ACTIVATION_TYPES.edit
        },
        panelsIsMultiplie(): boolean {
            return this.mode === CHANGE_ACTIVATION_TYPES.edit
        },
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        GCB2Connector(): IGCB2Connector {
            return this.project.connectors.find(
                el => el.connectorType === CONNECTOR_TYPES.GCB2
            ) as IGCB2Connector
        },
        breadcrumbs(): Array<any> {
            const activationTags = this.$router.currentRoute.query.tags
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: this.project.name,
                    to: `/project/${this.project.id}`,
                },
                {
                    text: 'Кого из клиентов стоит позвать',
                    to: `/project/${this.project.id}/GCB2${activationTags ? `?tags=${activationTags}` : ''}`,
                },
                {
                    text: 'Добавить активацию',
                    to: '',
                    disabled: true,
                },
            ]
        },
        activeSwitchLabel(): string {
            return this.activation.isRunning ? 'Деактивировать' : 'Активировать'
        },
        activationIsActiveTooltipText(): string {
            return this.activeSwitchLabel
        },
        runningActivationsLimit(): number {
            return this.$store.getters[`GCB2/${t.GET_ACTIVATIONS_COUNT_LIMIT}`](this.project.id)
        },
        isRunningSwitchDisabled(): boolean {
            return (
                this.runningActivationsCount >= this.runningActivationsLimit &&
                !this.activation.isRunning &&
                !this.runningActivations.map(el => el.id).includes(this.activation.id)
            )
        },
        savedSteps(): any[] {
            return this.steps.filter(el => el.saved)
        },
        availableStepsIds(): number[] {
            if (this.mode === CHANGE_ACTIVATION_TYPES.create) {
                const nextStepIndex =
                    this.steps.findIndex(el => el.id === this.savedSteps[this.savedSteps.length - 1]?.id) + 1
                const nextStep = this.steps[nextStepIndex]
                return [...this.savedSteps.map(el => el.id), nextStep?.id]
            } else {
                return this.steps.map(el => el.id)
            }
        },
        availableSteps(): EditActivationStep[] {
            return this.steps.filter(el => (el.showCondition ? el.showCondition(this.project) : true))
        },
        saveButtonDisabled(): boolean {
            return (
                !this.activation.name ||
                !this.activation.audienceId ||
                (!this.activation.testModePhone && this.activation.mode === 'Тестовый') ||
                this.steps.some(step => step.saveBtnDisabled)
            )
        },
        runningActivations(): any[] {
            return this.activationsList?.filter(el => el.isRunning) || []
        },
        runningActivationsCount(): number {
            return this.runningActivations.length || 0
        },
        messagingConnector(): Connector | undefined {
            return messages.getMessagingConnector(this.project.connectors) as Connector | undefined
        },
    },
    methods: {
        checkRole,
        async init() {
            this.loading = true
            this.activationsList = (await getActivations(this.project.id)).data
            if (!this.mode) {
                this.mode = CHANGE_ACTIVATION_TYPES.create as ChangeActivationMode
                this.$router.push({
                    path: this.$router.currentRoute.path,
                    query: Object.assign({}, this.$router.currentRoute.query, {
                        mode: CHANGE_ACTIVATION_TYPES.create,
                    }),
                })
            }
            if (this.mode === CHANGE_ACTIVATION_TYPES.create) {
                this.activation = editActivationService.getInitedCreatedActivation({
                    projectId: this.project.id,
                    runningActivationsCount: this.runningActivationsCount,
                    runningActivationsLimit: this.runningActivationsLimit,
                })
                this.activation['media'] = {}
            }
            if (this.mode === CHANGE_ACTIVATION_TYPES.edit && this.activationIdFromQuery) {
                const activationFromList = this.activationsList!.find(
                    el => el.id === this.activationIdFromQuery
                )
                if (!activationFromList) {
                    this.$router.push({
                        path: this.$router.currentRoute.path,
                        query: { mode: CHANGE_ACTIVATION_TYPES.create },
                    })
                    await this.init()
                    return
                }

                this.activation = await editActivationService.getInitedUpdatedActivation({
                    activationId: this.activationIdFromQuery,
                    projectId: this.project.id,
                    activationsList: this.activationsList!,
                })
                this.stepsIndexes = this.steps.map((el, i) => i)
            }
            if (!this.activation['media']) {
                this.activation['media'] = await api.gcb2
                    .getActivationMedia({
                        projectId: this.project.id,
                        id: this.activation.id,
                    })
                    .then(res => res.data['media'] || {})
            }

            const communicationTags = await api.gcb2
                .getCommunicationTags(this.project.id)
                .then(res => res.data!.filter(el => !el.deletedAt))

            this.activation.communicationTags = communicationTags

            const messagingConnector = messages.getMessagingConnector(this.project.connectors)
            if (messagingConnector) {
                const batchModeSettings = await api.massender
                .getLimitSettings({
                    projectId: this.project.id,
                    connectorType: messagingConnector!.connectorType,
                })
                .then(res => res.data)
                this.activation.batchModeSettings = batchModeSettings
            }

            this.loading = false
        },
        saveStep(step: EditActivationStep, index: number) {
            this.steps = replaceElementAtIndex(this.steps, index, Object.assign({}, step, { saved: true }))
            const nextStepIndex = index + 1
            this.stepsIndexes = nextStepIndex
            this.activeStepsIndexes = [nextStepIndex]
        },
        highlightStep(stepId: number) {
            const stepIndex = this.steps.findIndex(step => step.id === stepId)
            const step = this.steps[stepIndex]
            this.steps = replaceElementAtIndex(
                this.steps,
                stepIndex,
                Object.assign({}, step, { highlighted: true })
            )
            this.$scrollTo(`#step-${stepId}`, 300, {
                offset: -300,
                onDone: () => {
                    setTimeout(() => {
                        this.steps = replaceElementAtIndex(
                            this.steps,
                            stepIndex,
                            Object.assign({}, step, { highlighted: false })
                        )
                    }, 1500)
                },
            })
        },
        clear(step: EditActivationStep) {
            switch (step.id) {
                case 1:
                    this.activation.name = ''
                    this.activation.description = ''
                    break
                case 2:
                    this.activation.audienceId = null
                    break
                default:
                    break
            };
            (this.$refs[step.ref] as any)[0].clear()
        },
        updateSaveButton(e: boolean, step: any, index: number) {
            this.steps = replaceElementAtIndex(
                this.steps,
                index,
                Object.assign({}, step, { saveBtnDisabled: !e })
            )
        },
        async copyFromActivation({ step, activation }: { step: EditActivationStep; activation: Activation }) {
            const stepIndex = this.steps.findIndex(el => el.id === step.id)
            switch (step.id) {
                case 2:
                    this.activation.audienceId = activation.audienceId

                    this.updateSaveButton(true, step, stepIndex)
                    break
                case 3:
                    this.copyLoading = true
                    const copiedMessage = await getMessage({
                        id: activation.messageId,
                        projectId: this.project.id,
                    })
                    this.activation.message = Object.assign(
                        {},
                        {
                            id: null,
                            name: '',
                            group: '',
                            messageParts: copiedMessage.messageParts.map((el: any) => ({
                                id: el.id,
                                _id: _.uniqueId(),
                                partNumber: el.partNumber,
                                textVariant: el.textVariant,
                            })),
                            media: {},
                        }
                    )
                    this.copyLoading = false
                    break
                default:
                    break
            }
            this.updateSaveButton(true, step, stepIndex)
        },
        goToGCB2() {
            const activationTags = this.$router.currentRoute.query.tags
            this.$router.push({
                path: `/project/${this.project.id}/GCB2`,
                query: {
                    tags: activationTags,
                },
            })
        },
        goToCreatedActivation(activationId: number) {
            const activationTags = this.$router.currentRoute.query.tags
            this.$router.push({
                path: `/project/${this.project.id}/GCB2`,
                query: {
                    activationId: String(activationId),
                    highlight: String(activationId),
                    tags: activationTags,
                },
            })
        },
        async createActivation(saveAsNew?: boolean) {
            this.createOrEditLoading = true
            if (saveAsNew) {
                this.activation.isRunning = false
            }
            const createdActivation = await editActivationService.createActivation(
                this.activation,
                this.project
            )
            if (this.activation.isRunning) {
                setChangedActivationNotification({
                    projectId: this.project.id,
                    active: true,
                })
            }
            if (this.activation.excelFile?.file) {
                await this.uploadExcell(createdActivation.id)
            }
            this.createOrEditLoading = false
            if (saveAsNew) return this.goToCreatedActivation(createdActivation!.id)
            this.goToGCB2()
        },
        async updateActivation() {
            this.createOrEditLoading = true
            await editActivationService.updateActivation(this.activation, this.project)
            if (this.activation.isRunning) {
                setChangedActivationNotification({
                    projectId: this.project.id,
                    active: true,
                })
            }
            if (this.activation.excelFile?.file) {
                await this.uploadExcell(this.activation.id)
            }
            this.createOrEditLoading = false
            this.$store.dispatch('project/fetchProject', this.project.id)
            this.goToGCB2()
        },
        async deleteActivation() {
            this.createOrEditLoading = true
            await deleteActivation({ projectId: this.project.id, id: this.activation.id as number })
            this.createOrEditLoading = false
            this.goToGCB2()
        },
        async uploadExcell(activationId) {
            debugger
            const { data, error } = await api.file.uploadExcel({
                projectId: this.activation.projectId,
                file: this.activation.excelFile?.file,
                activationId: activationId,
            })
            if (error) {
                this.modalStore.open({ text: 'Произошла ошибка при загрузке файла' })
            } else {
                let notifyText = data.help_text
                if (data.data) {
                    notifyText += ' ' + data.data
                }

                this.modalStore.open({ title: data.message, text: notifyText })
            }
        },
        mapActivationExtraBeforeSave(extra: any) {
            const extraCopy = _.cloneDeep(extra)
            if (extraCopy?.automail?.intervalInMinutes < 15) {
                extraCopy.automail.intervalInMinutes = 15
            }

            return extraCopy
        },
        scrollToTarget() {
            if (!this.automailValidate.isSaveButtonClicked) {
                this.automailValidate.isSaveButtonClicked = true
            }
            if (this.mode === CHANGE_ACTIVATION_TYPES.create) {
                if (Array.isArray(this.stepsIndexes) && !this.stepsIndexes.includes(3)) {
                    this.stepsIndexes = this.stepsIndexes.concat(3)
            } else if (this.stepsIndexes !== 3) {
                this.stepsIndexes = [this.stepsIndexes].push(3)
            }
            }
            
            this.$scrollTo('#step-3', 800, {
                offset: -180,
            })
        },
    },

    async created() {
        const billingSore = useBillingStore()
        const isBillingEnabled = this.project.billingEnabled
        const isCommunicationTagsActive = isBillingEnabled
            ? billingSore.getCurrentTariff(this.project.id).id === 'tariff2'
            : false
        if (!isBillingEnabled || isCommunicationTagsActive) {
            this.steps.push({
                id: 8,
                title: 'Теги коммуникации',
                contentComponent: CommunicationTagSettings,
                ref: 'setting-8',
                saved: false,
                highlighted: false,
                saveBtnDisabled: false,
            } as any)
        }
        if (!checkRole(this.project.id, 'gcb2:activation:edit')) {
            this.$store.dispatch('callNotify', 'Нет прав')
            this.$router.go(-1)
        }
        await this.init()
    },
    mounted() {},
})
